import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Form, Row, Col, Button, CardFooter } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { APICALL } from '../../../helper/api/api';
import { imgBaseURL, toastifySuccess } from '../../../helper/Utility';
import ConfirmationModal from '../ConfirmationModal';
import AdminLoader from '../../components/AdminLoader';
import BackButton from '../BackButton';
import { useTranslation } from 'react-i18next';

const PlanEdit = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const data = location.state;

  // console.log(data);

  const [formData, setFormData] = useState({
    name: '',
    nameAr: '',
    durationInMonths: '',
    price: '',
    whatIncluded: [],
    whatIncludedAr: [],
    image: null,
  });

  useEffect(() => {
    if (data) {
      setFormData({
        name: data?.name || '',
        nameAr: data?.nameAr || '',
        durationInMonths: data?.durationInMonths || '',
        price: data?.price || '',
        whatIncluded: data?.whatIncluded ? data.whatIncluded : [''],
        whatIncludedAr: data?.whatIncludedAr ? data.whatIncludedAr : [''],
        image: null,
      });
    }
  }, [data]);

  const handleWhatIncludedChange = (index, value, isArabic = false) => {
    const updatedList = isArabic ? [...formData.whatIncludedAr] : [...formData.whatIncluded];
    updatedList[index] = value;
    setFormData({ ...formData, [isArabic ? 'whatIncludedAr' : 'whatIncluded']: updatedList });
  };

  const addWhatIncluded = () => {
    setFormData((prevState) => ({
      ...prevState,
      whatIncluded: [...prevState.whatIncluded, ''],
      whatIncludedAr: [...prevState.whatIncludedAr, ''],
    }));
  };

  const removeWhatIncluded = (index) => {
    const updatedWhatIncluded = formData.whatIncluded.filter((_, i) => i !== index);
    const updatedWhatIncludedAr = formData.whatIncludedAr.filter((_, i) => i !== index);
    setFormData({ ...formData, whatIncluded: updatedWhatIncluded, whatIncludedAr: updatedWhatIncludedAr });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const perms = new FormData();
      perms.append('planId', data?._id);
      perms.append('name', formData.name);
      perms.append('nameAr', formData.nameAr);
      perms.append('durationInMonths', formData.durationInMonths);
      perms.append('price', formData.price);
      formData.whatIncluded.forEach((item) => perms.append('whatIncluded[]', item));
      formData.whatIncludedAr.forEach((item) => perms.append('whatIncludedAr[]', item));
      perms.append('image', formData.image);

      const res = await APICALL("admin/createPlan", "post", perms);

      if (res?.status) {
        setLoading(false);
        navigate(-1);
        toastifySuccess(res?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Card className="card-cusotom card">
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center" style={{ gap: '10px' }}>
            <div className="d-flex align-items-center" style={{ gap: '10px' }}>
              <BackButton />
              <h2 className="title-admins-table m-0">{t('Edit Plan Details')}</h2>
            </div>
          </div>
        </CardHeader>
        {loading ? (
          <>
            <AdminLoader />
          </>
        ) : (
          <Form onSubmit={handleSubmit}>
            <CardBody>
              <div className="cutoms-login-artist">
                <Row>
                  <Col md={4} className="mb-4">
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>{t('Name')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        placeholder={t('Name')}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-4">
                    <Form.Group className="mb-3" controlId="nameAr">
                      <Form.Label>{t('Name')} (AR)</Form.Label>
                      <Form.Control
                        type="text"
                        name="nameAr"
                        value={formData.nameAr}
                        onChange={(e) => setFormData({ ...formData, nameAr: e.target.value })}
                        placeholder={t('Name') + "(AR)"}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={4} className="mb-4">
                    <Form.Group className="mb-3" controlId="durationInMonths">
                      <Form.Label>{t('Duration In Months')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="durationInMonths"
                        value={formData.durationInMonths}
                        onChange={(e) => setFormData({ ...formData, durationInMonths: e.target.value })}
                        placeholder={t('Duration In Months')}
                      />
                    </Form.Group>
                  </Col> */}
                  {/* <Col md={4} className="mb-4">
                    <Form.Group className="mb-3" controlId="price">
                      <Form.Label>{t('Price')}</Form.Label>
                      <Form.Control
                        type="text"
                        name="price"
                        value={formData.price}
                        onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                        placeholder={t('Price')}
                      />
                    </Form.Group>
                  </Col> */}
                </Row>

                {/* Dynamic "What Included" Section */}
                <Col md={12}>
                  <Form.Label>{t('What Included')}</Form.Label>
                  {formData.whatIncluded.map((item, index) => (
                    <Row key={index} className="align-items-center mb-2">
                      <Col md={5}>
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={item}
                          onChange={(e) => handleWhatIncludedChange(index, e.target.value)}
                          placeholder={t('What Included') + "(EN)"} 
                        />
                      </Col>
                      <Col md={5}>
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={formData.whatIncludedAr[index] || ''}
                          onChange={(e) => handleWhatIncludedChange(index, e.target.value, true)}
                          placeholder={t('What Included') + "(AR)"}
                        />
                      </Col>
                      <Col md={2}>
                        <Button type="button" variant="danger" onClick={() => removeWhatIncluded(index)}>
                          <i className="fa fa-trash"></i>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Button type="button" variant="success" onClick={addWhatIncluded}>
                    <i className="fa fa-plus"></i> {t('Add More')}
                  </Button>
                </Col>
              </div>
            </CardBody>
            <CardFooter>
              <Button type="submit" className="artist-btn btn btn-primary">
                {t('Update')}
              </Button>
            </CardFooter>
          </Form>
        )}
      </Card>
    </>
  );
};

export default PlanEdit;
