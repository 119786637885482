import React from "react";
import { useLocation } from "react-router-dom";
import "./PrivacyPolicy.css";

const TermsConditions = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get("lang"); // Get the 'lang' parameter

    const isArabic = lang === "ar";

    return (
        <div className="container">
            <div className="privacy-policy" style={{ direction: isArabic ? "rtl" : "ltr", textAlign: isArabic ? "right" : "left" }}>
                <h1>{isArabic ? "الشروط والأحكام" : "Terms & Conditions"}</h1>

                <h2>{isArabic ? "1. الأهلية" : "1. Eligibility"}</h2>
                <p>{isArabic ? "باستخدامك منصتنا، فإنك تقر وتتعهد بما يلي:" : "By using our platform, you represent and warrant that:"}</p>
                <ul>
                    <li>{isArabic ? "أنك تبلغ من العمر 18 عامًا على الأقل أو لديك موافقة من الوالدين/الوصي." : "You are at least 18 years old or have parental/guardian consent."}</li>
                    <li>{isArabic ? "لديك السلطة للدخول في هذا الاتفاق نيابة عن نفسك أو المنظمة التي تمثلها." : "You have the authority to enter into this agreement on behalf of yourself or the organization you represent."}</li>
                    <li>{isArabic ? "استخدامك للمنصة يتوافق مع جميع القوانين واللوائح المعمول بها." : "Your use of the platform complies with all applicable laws and regulations."}</li>
                </ul>

                <h2>{isArabic ? "2. مسؤوليات المستخدم" : "2. User Responsibilities"}</h2>
                <h3>{isArabic ? "2.1. إنشاء الحساب وصيانته" : "2.1. Account Creation and Maintenance"}</h3>
                <ul>
                    <li>{isArabic ? "توافق على تقديم معلومات دقيقة وكاملة ومحدثة عند إنشاء حساب على منصتنا." : "You agree to provide accurate, complete, and up-to-date information when creating an account on our platform."}</li>
                    <li>{isArabic ? "تتحمل مسؤولية الحفاظ على سرية بيانات تسجيل الدخول الخاصة بك." : "You are responsible for maintaining the confidentiality of your login credentials."}</li>
                    <li>{isArabic ? "توافق على إخطارنا فورًا بأي استخدام غير مصرح به لحسابك." : "You agree to notify us immediately of any unauthorized use of your account."}</li>
                </ul>

                <h3>{isArabic ? "2.2. الأنشطة المحظورة" : "2.2. Prohibited Activities"}</h3>
                <ul>
                    <li>{isArabic ? "توافق على عدم:" : "You agree not to:"}</li>
                    <li>{isArabic ? "استخدام المنصة لأي غرض غير قانوني أو احتيالي أو ضار." : "Use the platform for any unlawful, fraudulent, or harmful purpose."}</li>
                    <li>{isArabic ? "التدخل أو تعطيل عمل المنصة." : "Interfere with or disrupt the operation of the platform."}</li>
                    <li>{isArabic ? "محاولة الوصول غير المصرح به إلى أي جزء من المنصة أو أنظمتها." : "Attempt to gain unauthorized access to any part of the platform or its systems."}</li>
                    <li>{isArabic ? "مشاركة معلومات خاطئة أو مضللة." : "Share false or misleading information."}</li>
                </ul>

                <h2>{isArabic ? "3. الخدمات المقدمة" : "3. Services Provided"}</h2>
                <h3>{isArabic ? "3.1. للمدارس" : "3.1. Schools"}</h3>
                <p>{isArabic ? "نوفر أدوات للمدارس لإدارة سجلات الطلاب، والتواصل مع أولياء الأمور، والتعامل مع المهام الإدارية بكفاءة." : "We provide tools for schools to manage student records, communicate with parents, and handle administrative tasks efficiently."}</p>

                <h3>{isArabic ? "3.2. لشركات النقل" : "3.2. Transport Companies"}</h3>
                <p>{isArabic ? "نقدم خدمات لتبسيط لوجستيات النقل، بما في ذلك تخطيط المسارات، وتتبع المركبات، وجدولة الرحلات." : "We offer services to streamline transport logistics, including route planning, vehicle tracking, and scheduling."}</p>

                <h3>{isArabic ? "3.3. لأولياء الأمور" : "3.3. Parents"}</h3>
                <p>{isArabic ? "يمكن للآباء الوصول إلى معلومات أطفالهم الأكاديمية ومعلومات النقل، وكذلك التواصل مع المدارس ومقدمي خدمات النقل." : "Parents can access their child's academic and transport information, as well as communicate with schools and transport providers."}</p>
            </div>
        </div>
    );
};

export default TermsConditions;
